/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"

import Header from "./header"
import "./layout.css"

const Layout = ({ children, nomargin }) => {
  return (
    <>
      <Header siteTitle={"LECKR"} nomargin={nomargin} />
      <div
        style={{
          margin: `0 auto`,
          maxWidth: nomargin ? undefined : 960,
          padding: nomargin ? undefined : `0px 1.0875rem 1.45rem`,
          paddingTop: 0,
        }}
      >
        <main>
          <div style={{ minHeight: 500 }}>{children}</div>
        </main>
      </div>

      <footer>
        <div style={{ backgroundColor: "black", color: "white", padding: 50 }}>
          <div style={{ float: "left" }}>
            Copyright © {new Date().getFullYear()} LECKR. - Alle rechten
            voorbehouden
          </div>

          <div style={{ float: "left", paddingLeft: 50 }}>
            <div style={styles.menu}>
              <Link to="/" id="menu">
                Home
              </Link>
            </div>

            <div style={styles.menu}>
              <Link to="/about" id="menu">
                Over mij
              </Link>
            </div>

            <div style={styles.menu}>
              <Link to="/portfolio" id="menu">
                Werk
              </Link>
            </div>

            <div style={styles.menu}>
              <a
                id="menu"
                href="https://blog.karsens.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Blog
              </a>
            </div>

            <div style={styles.menu}>
              <Link to="/contact" id="menu">
                Contact
              </Link>
            </div>
          </div>
        </div>
      </footer>
    </>
  )
}

const styles = {
  menu: {
    paddingLeft: 10,
    paddingRight: 10,
    backgroundColor: "#111",
    float: "left",
  },
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
