import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

const Header = ({ siteTitle, nomargin }) => (
  <header
    style={{
      background: `black`,
      marginBottom: nomargin ? 0 : `1.45rem`,
    }}
  >
    <div
      style={{
        margin: `0 auto`,
        maxWidth: 960,
        height: 75,
      }}
    >
      <div style={styles.menu}>
        <Link
          to="/"
          id="menu"
          style={{
            color: `white`,
            fontSize: 32,
            textDecoration: `none`,
          }}
        >
          {siteTitle}
        </Link>
      </div>

      <div style={styles.menu}>
        <Link to="/about" id="menu">
          Over mij
        </Link>
      </div>

      <div style={styles.menu}>
        <Link to="/portfolio" id="menu">
          Werk
        </Link>
      </div>

      <div style={styles.menu}>
        <a
          id="menu"
          href="https://blog.karsens.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Blog
        </a>
      </div>

      <div style={styles.menu}>
        <Link to="/contact" id="menu">
          Contact
        </Link>
      </div>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

const styles = {
  menu: {
    padding: 20,
    backgroundColor: "#111",
    float: "left",
  },
}
export default Header
